import * as React from "react"
import styled from 'styled-components';
import Layout from "../components/layout"
import Seo from "../components/seo"


import Stack from '../components/Home/Stack';
import stackdata from '../Json/stackdata.json';
import Banner from '../components/Home/Banner';
import bannerdata from '../Json/bannerdata.json';




const Topman = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1em;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

const SectionStackGroup = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(0, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

const SectionBannerGroup = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  justify-items: center;
  color: white;

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

const SectionCellGroup = styled.div`
  padding-top: 30px;
  max-width: 1700px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

const IndexPage = () => (
  <Layout>
    <Seo title="Stuxio Network" />
    <div className="Hero">
      <div className="HeroGroup">
        <Topman>
          <SectionBannerGroup>
            {bannerdata.banners.map((banner) => (
              <Banner
                title={banner.title}
                image={banner.image}
                text={banner.text}
                url={banner.url}
              />
            ))}
          </SectionBannerGroup>
          <SectionStackGroup>
            {stackdata.stacks.map((stack) => (
              <Stack
                title={stack.title}
                image={stack.image}
                text={stack.text}
                url={stack.url}
              />
            ))}
          </SectionStackGroup>
        </Topman>
      </div>
    </div>
    
  </Layout>
)

export default IndexPage
